<template>
  <div>
    <v-container fluid>
      <div class="main">
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-space-between mb-3">
              <v-spacer/>
              <div>
                <v-btn color="gifty"
                       text
                       to="/fees/types">
                  <v-icon left>mdi-cog</v-icon>
                  Types des frais
                </v-btn>
                <v-btn class="rounded-lg"
                       color="gifty"
                       dark
                       depressed
                       to="/fees/create">
                  <v-icon left>mdi-plus</v-icon>
                  Ajouter un frais
                </v-btn>
              </div>
            </div>

            <v-card class="rounded-lg shadow">
              <v-overlay :value="overlay" absolute color="white" opacity="0.5">
                <v-progress-circular
                        color="primary"
                        indeterminate
                        size="50"
                ></v-progress-circular>
              </v-overlay>

              <v-card-title>
                <div>
                  <v-text-field
                          v-model="keyword"
                          append-icon="mdi-magnify"
                          clearable
                          dense
                          filled
                          hide-details
                          placeholder="Rechercher..."
                          rounded
                          single-line
                  />
                </div>
                <v-spacer/>
                <v-btn class="rounded-lg"
                       color="grey"
                       text
                       @click="$refs.filterDialog.open()">
                  <v-icon left>mdi-filter-outline</v-icon>
                  Filtre
                </v-btn>

                <v-btn color="grey"
                       icon
                       @click="getFees(null)">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>

              </v-card-title>

              <v-divider/>

              <v-card-text v-if="isLoading">
                <v-skeleton-loader type="table"/>
              </v-card-text>

              <div v-else>
                <v-card-text class="pa-0">
                  <v-simple-table v-if="fees.length">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Statut</th>
                        <th>Nom</th>
                        <th>Valeur</th>
                        <th>Montant max</th>
                        <th>Montant min</th>
                        <th>Montant de base</th>
                        <th>Services</th>
                        <th class="text-center">Tout le monde</th>
                        <th>Type</th>
                        <th>Créé le</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(fee,index) in fees"
                          :key="index"
                      >
                        <td>
                          <v-switch v-model="fee.is_active"
                                    class="pa-0 ma-0"
                                    color="success"
                                    dense
                                    hide-details
                                    readonly
                                    @click="changeStatus(fee.id,index)"></v-switch>
                        </td>

                        <td>
                          {{ fee.name }}
                        </td>

                        <td>
                                      <span class="gifty--text font-weight-medium">
                                          {{ fee.value }}%
                                      </span>
                        </td>

                        <td>
                                       <span class="text-primary font-weight-medium">
                                            {{ CurrencyFormatting(fee.max_amount) }} DZD
                                       </span>
                        </td>


                        <td>
                                       <span class="text-primary font-weight-medium">
                                            {{ CurrencyFormatting(fee.min_amount) }} DZD
                                       </span>
                        </td>

                        <td>
                                       <span class="font-weight-medium">
                                            {{ CurrencyFormatting(fee.basic_amount) }} DZD
                                       </span>
                        </td>

                        <td>
                          <v-chip color="info"
                                  dark
                                  small
                                  @click="$refs.servicesDialog.open(fee.services)">
                            <v-icon left small>mdi-layers-triple</v-icon>
                            {{ fee.services.length }}
                          </v-chip>
                        </td>

                        <td class="text-center">
                          <v-icon v-if="fee.assignment_type === 'all'" color="success">
                            mdi-check-circle
                          </v-icon>
                          <v-icon v-else color="success">mdi-circle-outline</v-icon>
                        </td>

                        <td>
                          {{ fee.type ? fee.type.name : '-' }}
                        </td>

                        <td class="text-no-wrap">
                          <v-icon :style="{marginTop:'-3px'}"
                                  color="gifty"
                                  small>mdi-clock-outline
                          </v-icon>
                          {{ fee.created_at }}
                        </td>

                        <td>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon
                                     v-bind="attrs"
                                     v-on="on"
                              >
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list dense>
                              <v-list-item :to="'/fees/'+fee.id+'/update'">
                                <v-list-item-icon>
                                  <v-icon>mdi-pencil-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Modifier</v-list-item-title>
                              </v-list-item>

                              <v-list-item @click="remove(fee.id)">
                                <v-list-item-icon>
                                  <v-icon>mdi-delete-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Supprimer</v-list-item-title>
                              </v-list-item>

                            </v-list>
                          </v-menu>

                        </td>

                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div v-else>
                    <NoResults @reload="getFees"/>
                  </div>
                </v-card-text>

                <v-divider v-if="total > 10"/>

                <v-card-actions v-if="total > 10">

                  <div :style="{width: '120px'}">
                    <v-select v-model="perPage"
                              :items="[10,20,30,50,100]"
                              dense
                              hide-details
                              label="Ligne par page"
                              outlined></v-select>
                  </div>

                  <v-spacer/>

                  <v-pagination v-model="pagination.current"
                                :length="pagination.total"
                                circle
                                total-visible="8"
                                @input="getFees"></v-pagination>

                </v-card-actions>

              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <ServicesDialog ref="servicesDialog"/>
      <FilterDialog ref="filterDialog" @filter="getFees"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import ServicesDialog from "./components/ServicesDialog.vue";
import FilterDialog from "./components/FilterDialog.vue";

export default {
   components: {NoResults, ServicesDialog, FilterDialog},
   data() {
      return {
         keyword: '',
         id: '',
         overlay: false,
         isLoading: false,
         fees: [],

         total: 0,
         perPage: 10,
         pagination: {
            current: 1,
            total: 0
         },
      };
   },
   methods: {
      getFees(filter) {
         this.isLoading = true
         HTTP.get('/v1/fees?page=' + this.pagination.current, {
            params: {
               perPage: this.perPage,
               fee_type_id: filter ? filter.fee_type_id : null,
               is_active: filter ? filter.is_active : null,
               keyword: this.keyword
            }
         }).then(res => {
            this.pagination.current = res.data.data.current_page;
            this.pagination.total = res.data.data.last_page;
            this.total = res.data.data.total;

            this.fees = res.data.data.data

            this.isLoading = false
         }).catch(err => {
            this.isLoading = false
            console.log(err)
         })
      },
      remove(id) {
         this.id = id;
         this.$confirm_dialog = true;
      },
      deleteItem() {
         this.overlay = true;
         HTTP.post("/v1/fees/delete", {
            id: this.id,
         }).then(() => {
            this.overlay = false;
            let i = this.fees.findIndex((el) => el.id === this.id);
            this.$delete(this.fees, i);
            this.id = null;
            this.$successMessage = "Cet élément a été supprimé avec succès.";
         }).catch((err) => {
            this.overlay = false;
            console.log(err);
         });
      },
      changeStatus(id, index) {
         this.overlay = true;
         HTTP.post("/v1/fees/change-status", {
            id: id,
         }).then(() => {
            this.overlay = false;
            this.fees[index].is_active = !this.fees[index].is_active
            this.$successMessage = "Cet élément a été modifié avec succès.";
         }).catch((err) => {
            this.overlay = false;
            console.log(err);
         });
      },
   },
   created() {
      this.getFees();
   },
   watch: {
      $is_confirm: function (el) {
         if (el) {
            this.deleteItem();
            this.$is_confirm = false;
         }
      },
      perPage() {
         this.pagination.current = 1
         this.getFees();
      },
      keyword() {
         this.getFees();
      },
   }
};
</script>

<style scoped></style>
